@use 'styles-constants' as *;

@use 'bootstrap/scss/bootstrap-reboot' as *;
@use 'bootstrap/scss/bootstrap-grid' as *;
@use 'bootstrap/scss/bootstrap' as * with (
    $primary: $aql-green,
    $min-contrast-ratio: 2.8
);
@import '@fortawesome/fontawesome-free/css/all.css';
@import url(//fonts.googleapis.com/css?family=Open+Sans:400,600,700,300); // Open Sans font

@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-alpine.css";

:root {
    --bs-body-color: $aql-font-color;
    --bs-body-font-size: 0.9rem;
}

body {
    color: $aql-font-color;
    font-size: 0.9rem;
}

.cursor-pointer { cursor: pointer; }
.cursor-default { cursor: default; }
.cursor-zoom-in { cursor: zoom-in; }

.popover-background {
    background-color: $aql-lightergreen;
}

.card {
    border-radius: 0;
    background-color: $aql-background;
    border-color: transparent;
}

.ag-root-wrapper {
    & .ag-cell-wrap-text {
        word-break: normal;
    }
    & .ag-cell-focus {
        border-color: transparent !important;
    }

    & .ag-layout-auto-height {
        & .ag-center-cols-clipper,
        & .ag-center-cols-container {
            min-height: 0 !important;
        }
    }

    .ag-theme-alpine & {
        .ag-header-cell, .ag-header-group-cell {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            &.aql-green-header {
                background-color: $aql-lightergreen !important;
            }
            &.aql-green-header-dark {
                background-color: #C4E7C2 !important;
            }
            &.aql-orange-header {
                background-color: $aql-lighterorange;
            }
            &.aql-gray-header {
                background-color: #f7f7f7;
            }
            &.aql-underline-header{
                .ag-header-cell-text{
                    text-decoration: underline;
                }
            }
            &.multiline{
                text-overflow: clip;
                overflow: visible;
                white-space: normal;
                height: auto;
            }
        }
        .aql-orange-cell {
            background-color: $aql-lightorange-trans;
        }
        .aql-green-cell {
            background-color: $aql-lightergreen-trans;
        }
        .aql-gray-cell {
            background-color: $aql-darker-background;
        }
        .aql-completed-cell {
            color: $aql-light-font-color;
        }
    }

    & .ag-header-cell-text {
        font-size: 0.9em;
        font-weight: 600;
        color: #6a6c6f;
    }

    & .ag-cell-value {
        width: 100%;
    }

    .ag-row-selected {
        //background-color: $aql-lightorange !important;
    }

    .ag-row-hover {
        //background-color: $aql-lighterorange;
    }
}
.ag-header-container .ag-header-row:last-child {
    height: 10px;
}

.images-regression-plots {
    .ag-header-cell-label .ag-sort-indicator-container .ag-sort-order {
        display: none
    }
}
