@use 'sass:color';

//$grid-breakpoints: (
//    xs: 0,
//    sm: 576px,
//    md: 768px,
//    lg: 992px,
//    xl: 1200px
//);

$aql-green: #65AC1E;
$aql-lightgreen: color.adjust($aql-green, $lightness: 40%);
$aql-lightergreen: color.adjust($aql-green, $lightness: 52.5%);
$aql-lightergreen-trans: color.adjust($aql-lightgreen, $alpha: -50);
$aql-orange: #f39500;

$aql-lightorange: color.adjust($aql-orange, $lightness: 40%);
$aql-lightorange-trans: color.adjust($aql-lightorange, $alpha: -50);
$aql-lighterorange: color.adjust($aql-orange, $lightness: 47%);
$aql-background: #F0F0F0;
$aql-darker-background: color.adjust($aql-background, $lightness: -20%);
$aql-background-hover: #f7f8fa;
$aql-background-tooltip: #34495e;
$aql-panel-border: #e4e5e7;

$aql-font-color: #6a6c6f;
$aql-light-font-color: color.adjust($aql-font-color, $lightness: 30%);

$aql-main-header-height: 57px;
$aql-page-header-height: 53px;
